<template>
    <div>
        <a
            v-if="notify.type === 'group'"
            class="flex flex-col gap-2 rounded-t-md p-2 bg-gray-400"
            :href="`/pipeline/${notify.pipeline_id}/show`"
            @click.prevent.stop="toRoute(`/pipeline/${notify.pipeline_id}/show`)"
        >
            <UserNotificationDeal
                :pipelineId="notify.pipeline_id"
                :deal="{
                    id: notify.deal_id,
                    name: notify.deal_name,
                    date: notify.deal_date,
                }"
                :notify="notify"
                @onClickNotify="$emit('onClickNotify')"
            />

            <p class="text-white mb-0 text-left">
                {{ notify.pipeline_name }}: <span>{{ notify.status_name }}</span>
            </p>
        </a>
        <div class="bg-gray-300">
            <Notify
                class="py-2 block"
                v-for="item in notify.notifications"
                @click="handleClickNotify(item)"
                :notify="item"
                :key="item.id"
                :show_border="notify.notifications[notify.notifications.length - 1]?.id !== item.id"
            />
            <div class="ml-4 pb-2 text-blue-600 flex align-center" v-if="notify.more.length > 0">
                <span class="mr-2">
                    <ChevronUpIcon class="w-6 h-6" v-show="show_more" />
                    <ChevronDownIcon class="w-6 h-6" v-show="!show_more" />
                </span>
                <button @click="show_more = !show_more" type="button">
                    Показать прошлую активность
                </button>
            </div>
            <Notify
                v-show="show_more"
                class="py-2 block"
                v-for="item in notify.more"
                @click="handleClickNotify(item)"
                :notify="item"
                :key="item.id"
                :show_border="notify.more[notify.more.length - 1]?.id !== item.id"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import UserNotificationDeal from '@/components/notifications/UserNotificationDeal.vue';
import Notify from '@/views/users/notifications/Notify.vue';

export default defineComponent({
    components: { Notify, UserNotificationDeal },
    props: {
        notify: {
            required: true,
        },
    },
    emits: ['more'],
    data() {
        return {
            show_more: false,
        };
    },
    methods: {
        handleClickNotify(item) {
            if (item.note_id) {
                this.$router.push({
                    name: 'dealEdit',
                    params: { id: this.notify.pipeline_id, deal_id: this.notify.deal_id },
                    hash: `#note_id=${item.note_id}`,
                });
            }
        },
        toRoute(link) {
            const options = { path: link };
            this.$router.push(options);
        },
    },
});
</script>

<style scoped></style>
